import React from "react"

export default function Footer(){
    const footerLinks = [
        {image: "/images/bc/location-white-icon.png", link: "https://maps.app.goo.gl/x14N2S1vqKRf6zVWA"},
        {image: "/images/bc/instagram-white-icon.webp", link: "https://www.instagram.com/regenicstemcell/"},
        {image: "/images/bc/call-white-icon.png", link: "https://wa.me/6281188884321"},
        {image: "/images/bc/website-white-icon.png", link: "https://regenic.co.id"}
    ]

    return(
        <div className="w-full flex flex-col items-center mt-16 md:mt-20">
            <div className="flex gap-4 mb-4">
                {footerLinks.map(({image, link}) => (
                    <a href={link} target="__blank">
                        <img src={image} alt="" className="w-9 h-9 object-cover aspect-square"/>
                    </a>
                ))}
            </div>
            <p className="text-center text-base">Cell Therapy | New Hope New Life</p>
        </div>
    )
}